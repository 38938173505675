import React from "react"

const Heading = props => (
  <h3
    className="bg-secondary text-light mb-3 p-3 fw-bold shadow"
    style={{
      borderRadius: "4px",
    }}
  >
    {props.children}
  </h3>
)

export default Heading
