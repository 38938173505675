exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artificial-intelligence-js": () => import("./../../../src/pages/artificial_intelligence.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-js" */),
  "component---src-pages-author-js": () => import("./../../../src/pages/author.js" /* webpackChunkName: "component---src-pages-author-js" */),
  "component---src-pages-biomedical-instrumentation-js": () => import("./../../../src/pages/biomedical_instrumentation.js" /* webpackChunkName: "component---src-pages-biomedical-instrumentation-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-digital-electronics-js": () => import("./../../../src/pages/digital_electronics.js" /* webpackChunkName: "component---src-pages-digital-electronics-js" */),
  "component---src-pages-embedded-systems-js": () => import("./../../../src/pages/embedded_systems.js" /* webpackChunkName: "component---src-pages-embedded-systems-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-live-js": () => import("./../../../src/pages/mdx_live.js" /* webpackChunkName: "component---src-pages-mdx-live-js" */),
  "component---src-pages-music-player-js": () => import("./../../../src/pages/music_player.js" /* webpackChunkName: "component---src-pages-music-player-js" */),
  "component---src-pages-notes-js": () => import("./../../../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-react-live-js": () => import("./../../../src/pages/react_live.js" /* webpackChunkName: "component---src-pages-react-live-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-signals-js": () => import("./../../../src/pages/signals.js" /* webpackChunkName: "component---src-pages-signals-js" */),
  "component---src-pages-tasks-js": () => import("./../../../src/pages/tasks.js" /* webpackChunkName: "component---src-pages-tasks-js" */),
  "component---src-pages-terms-of-services-js": () => import("./../../../src/pages/terms_of_services.js" /* webpackChunkName: "component---src-pages-terms-of-services-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web_development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-templates-artificial-intelligence-post-js": () => import("./../../../src/templates/artificial_intelligence-post.js" /* webpackChunkName: "component---src-templates-artificial-intelligence-post-js" */),
  "component---src-templates-biomedical-instrumentation-post-js": () => import("./../../../src/templates/biomedical_instrumentation-post.js" /* webpackChunkName: "component---src-templates-biomedical-instrumentation-post-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-digital-electronics-post-js": () => import("./../../../src/templates/digital_electronics-post.js" /* webpackChunkName: "component---src-templates-digital-electronics-post-js" */),
  "component---src-templates-embedded-systems-post-js": () => import("./../../../src/templates/embedded_systems-post.js" /* webpackChunkName: "component---src-templates-embedded-systems-post-js" */),
  "component---src-templates-web-development-post-js": () => import("./../../../src/templates/web_development-post.js" /* webpackChunkName: "component---src-templates-web-development-post-js" */)
}

