import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/nightOwl"
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live"
import { useMDXScope } from "gatsby-plugin-mdx/context"

const RE = /{([\d,-]+)}/

const calculateLinesToHighlight = meta => {
  if (!RE.test(meta)) {
    return () => false
  } else {
    const lineNumbers = RE.exec(meta)[1]
      .split(",")
      .map(v => v.split("-").map(v => parseInt(v, 10)))
    return index => {
      const lineNumber = index + 1
      const inRange = lineNumbers.some(([start, end]) =>
        end ? lineNumber >= start && lineNumber <= end : lineNumber === start
      )
      return inRange
    }
  }
}

const copyToClipboard = str => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str).then(
      function () {
        console.log("Copying to clipboard was successful!")
      },
      function (err) {
        console.error("Could not copy text: ", err)
      }
    )
  } else if (window.clipboardData) {
    // Internet Explorer
    window.clipboardData.setData("Text", str)
  }
}

const CodeBlock = ({
  codeString,
  style,
  language,
  file,
  metastring,
  ...props
}) => {
  const shouldHighlightLine = calculateLinesToHighlight(metastring)
  const [isCopied, setIsCopied] = React.useState(false)
  const components = useMDXScope()
  if (props["react-live"]) {
    return (
      <LiveProvider code={codeString} scope={components} theme={theme}>
        <div className="row">
          <div className="col-lg-6">
            <LiveEditor
              className="shadow"
              style={{
                ...style,
                position: "relative",
                borderRadius: "0.3em 0.3em 0.3em 0.3em",
                border: "1px solid rgb(97, 137, 189)",
              }}
            />
          </div>
          <div className="col-lg-6">
            <LivePreview />
          </div>
        </div>
        <LiveError />
      </LiveProvider>
    )
  } else {
    return (
      <div className="pre-margin">
        {language ? (
          <div
            className="btn btn-warning"
            style={{
              position: "relative",
              top: "7.7rem",
              left: "1rem",
              padding: "8px 12px",
              borderRadius: "0 0 0.3em 0.3em",
              lineHeight: "1",
              cursor: "default",
              zIndex: 2,
            }}
          >{`${language}`}</div>
        ) : (
          <div
            className="btn btn-warning"
            style={{
              position: "relative",
              top: "7.7rem",
              left: "1rem",
              padding: "8px 12px",
              borderRadius: "0 0 0.3em 0.3em",
              lineHeight: "1",
              cursor: "default",
              zIndex: 2,
            }}
          >{`js`}</div>
        )}

        <button
          className="btn btn-primary"
          style={{
            position: "relative",
            top: "7.7rem",
            left: "1rem",
            marginLeft: "1rem",
            padding: "8px 12px",
            borderRadius: "0 0 0.3em 0.3em",
            lineHeight: "1",
            zIndex: 2,
          }}
          onClick={() => {
            copyToClipboard(codeString)
            setIsCopied(true)
            setTimeout(() => setIsCopied(false), 3000)
          }}
        >
          {isCopied ? "🎉 Copied!" : "Copy"}
        </button>

        {file ? (
          <div
            style={{
              position: "relative",
              top: "2.6rem",
              left: "0rem",
              padding: "14px",
              lineHeight: "1",
              color: "#50fa7b",
              backgroundColor: "#011627",
              borderRadius: "0.3em 0.3em 0 0",
              border: "1px solid rgb(97, 137, 189)",
              cursor: "default",
              zIndex: 3,
              minWidth: "100%",
              fontWeight: "600",
              fontSize: "1.2rem",
              overflow: "hidden",
            }}
          >
            {`${file}`}
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              top: "2.6rem",
              left: "0rem",
              padding: "14px",
              lineHeight: "1",
              color: "#50fa7b",
              backgroundColor: "#011627",
              borderRadius: "0.3em 0.3em 0 0",
              border: "1px solid rgb(97, 137, 189)",
              cursor: "default",
              zIndex: 3,
              minWidth: "100%",
              fontWeight: "600",
              fontSize: "1.2rem",
              overflow: "hidden",
            }}
          >
            {`code`}
          </div>
        )}

        <Highlight
          {...defaultProps}
          code={codeString}
          language={language}
          theme={theme}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <div className="gatsby-highlight" data-language={language}>
              <pre
                className={className}
                style={{
                  ...style,
                  paddingTop: "4rem",
                  paddingBottom: "2rem",
                  paddingLeft: "0rem",
                  paddingRight: "2rem",
                  position: "relative",
                  borderRadius: "0 0 0.3em 0.3em",
                  border: "1px solid rgb(97, 137, 189)",
                }}
              >
                {tokens.map((line, i) => {
                  const lineProps = getLineProps({ line, key: i })

                  if (shouldHighlightLine(i)) {
                    lineProps.className = `${lineProps.className} highlight-line`
                  }

                  return (
                    <div {...lineProps}>
                      <span className="line-number-style">{i + 1}</span>
                      {line.map((token, key) => (
                        <span {...getTokenProps({ token, key })} />
                      ))}
                    </div>
                  )
                })}
              </pre>
            </div>
          )}
        </Highlight>
      </div>
    )
  }
}

export default CodeBlock
